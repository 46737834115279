// Modal.js
import React, { useEffect } from "react";

import { CloseIcon } from "../components/Icons";

const Modal = ({ title, content, sections, onClose, placeholderText }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  return (
    <div className="d-flex justify-center align-center modal">
      <div className="w100 modal-content">
        <div className="container">

        <button
          className="fr btn btn-transparent close-button"
          onClick={onClose}
        >
          <i className="fr icon icon-sm">
            <CloseIcon />{" "}
          </i>
        </button>
        
        {content ? (
          <div className="modal-content">{content}</div>
        ) : (
          <div className="fl w100 d-flex align-center justify-center text-caps modal-header">
            <h2 className="text-xl card-title">{title}</h2>
            <h1
              className={`text-xxxl card-placeholder ${
                !content && !sections ? "" : "hidden"
              }`}
            >
              {placeholderText}
            </h1>
          </div>
        )}

        {sections.map((section, index) => (
          <React.Fragment key={index}>{section}</React.Fragment>
        ))}
      </div>
    </div>
    </div>
  );
};

export default Modal;
