import {
  AboutSection,
  ResumeContainer,
  MilestonesSection,
  PortfolioSection,
} from "./CardContent";
import profilePhoto from "./assets/profile.png";
import highlighted from "./assets/images/highlight.jpg";
import exp from "./assets/images/exp.svg";
import about from "./assets/images/About.svg";
import portfolio from "./assets/images/Portfolio.svg";
import showcase from "./assets/images/showcase.png";

// Skills
import FigmaLogo from "./assets/figma.svg";
import ReactLogo from "./assets/react.svg";
import HTMLLogo from "./assets/html.svg";
import SCSSLogo from "./assets/scss.svg";
import CSSLogo from "./assets/css.svg";
import CreativeSuiteLogo from "./assets/cc.svg";
import ChatGptLogo from "./assets/chatgpt.svg";
import GoogleLogo from "./assets/google.svg";
import BalsmiqeLogo from "./assets/balsmiq.svg";

// Icons

import { Briefcase, checkDone, faceHappy } from "./components/Icons";

// Portfolio Images

import cccPreview from "./assets/work/CCC/ccc-preview.jpg";
import cccCover from "./assets/work/CCC/ccc-cover.jpg";
// import landing from "./assets/work/CCC/Landing.jpg";
import innerPages from "./assets/work/CCC/innerPages.png";
import KBISSPreview from "./assets/work/KBISS/KBISS-preview.jpg";
import kbissCover from "./assets/work/KBISS/Cover.jpg";
import kbissinnerPages from "./assets/work/KBISS/innerPages.png";
import FNGPreview from "./assets/work/FNG/FNG-preview.jpg";
import fngCover from "./assets/work/FNG/Cover.jpg";
import fnginnerPages from "./assets/work/FNG/innerPages.png";
import ProcurementPreview from "./assets/work/Procurement/Procurement-preview.jpg";
import ProcurementCover from "./assets/work/Procurement/Cover.jpg";
import ProcurementinnerPages from "./assets/work/Procurement/innerPages.png";
import EstimatePreview from "./assets/work/Estimate/Estimate-Preview.jpg";
import EstimateCover from "./assets/work/Estimate/Estimate-Cover.jpg";
import EstimateinnerPages from "./assets/work/Estimate/innerPages.png";
import CityAuroraPreview from "./assets/work/CityAurora/CityAurora-Preview.jpg";
import CityAuroraCover from "./assets/work/CityAurora/CityAurora-Cover.jpg";
import CityAurorainnerPages from "./assets/work/CityAurora/CityAurora-innerPages.png";

import DesignSystemPreview from "./assets/work/DesignSystem/DesignSystemPreview.jpg";
import DesignSystemCover from "./assets/work/DesignSystem/DesignSystemCover.png";
import DesignSysteminnerPages from "./assets/work/DesignSystem/DesignSysteminnerPages.png";

// import resume from "./../public/Resume.pdf"

// portfolioData.js
const portfolioData = [
  {
    id: 1,
    title: "Card 1",
    type: "nonClickable",
    content: (
      <>
        <img src={highlighted} alt={highlighted} className="hightlight-prv" />
        <img src={exp} alt={exp} className="exp" />
      </>
    ),
  },

  {
    id: 2,
    title: "About Me",
    type: "clickable",
    placeholder: (
      <>
        <img src={about} alt={about} className="placeholder-about" />
      </>
    ),
    placeholderText: "Resume",
    sections: [
      <AboutSection
        key="about-section"
        profilePhoto={profilePhoto}
        description={`
    Hello, I'm <span class="highlight-word">Ajay Kumar</span>, a results-driven UI/UX Designer with a knack for blending creativity and functionality. I <span class="highlight-word"> specialize</span> in crafting intuitive, delightful digital experiences by <span class="highlight-word"> cross-functional teams.</span> From concept to prototype, I ensure a harmonious fusion of <span class="highlight-word">innovation</span> and usability.
  `}
        details={{
          contact: "+91 97 296 39 401",
          email: "ajaykra84@gmail.com",
        }} x
        button={{
          downloadResume: "Fetch My CV 📖 ",
        }}
        cvPdfUrl="/Resume.pdf"
      />,

      <ResumeContainer
        key="resume-container"
        resumeData={[
          {
            title: "Experience",
            content: "Details about experience",
      
            exp2: "Aug 2021 - Jan 2022",
            orgname2: "KocharTech",
            jobinfo2: (
              <>
                <strong className="highlight-word">UI/UX Designer</strong> redesign at KocharTech, harmonizing workflows. Collaborating with product managers and developers, employing HTML, SCSS, CSS, and JavaScript for cohesive design. Iteratively refining designs through user research and testing to optimize engagement.
              </>
            ),
      
            exp3: "Jan 2022 - Till",
            orgname3: "AtkinsRéalis",
            jobinfo3: (
              <>
                As the <strong className="highlight-word">UI/UX Designer</strong> at AtkinsRéalis since January 2022, I have been at the forefront of enhancing user experiences by driving innovative UI/UX strategies. I design process from ideation to implementation, ensuring that our designs are not only visually compelling but also user-centric and aligned with business goals. By collaborating closely with cross-functional teams, I ensure that our Figma-based design systems are effectively adapted across various platforms, maintaining consistency and responsiveness. My role emphasizes in design thinking, user advocacy, and delivering seamless digital experiences.
              </>
            ),
          },
          {
            title: "Favorable Turns",
            content: "Details about Favorable Turns",

            exp2: "October 2021",
            orgname2: "Appreciation - Good Work!",
            jobinfo2: "KocharTech",

            exp3: "September 2023",
            orgname3: "#ThankYou - Collaboration",
            jobinfo3: "AtkinsRéalis",
          },
          // ... (Other items)
        ]}
        skillsData={[
          {
            skillGroupName: "Tool & Software",
            skills: [
              {
                skillName: "Figma",
                img: FigmaLogo,
              },
              {
                skillName: "ReactJs",
                img: ReactLogo,
              },
              {
                skillName: "HTML",
                img: HTMLLogo,
              },
              {
                skillName: "SCSS",
                img: SCSSLogo,
              },
              {
                skillName: "CSS",
                img: CSSLogo,
              },
              {
                skillName: "Creative Suite",
                img: CreativeSuiteLogo,
              },
              {
                skillName: "Chat GPT",
                img: ChatGptLogo,
              },
              {
                skillName: "Google",
                img: GoogleLogo,
              },
              {
                skillName: "Balsmiq",
                img: BalsmiqeLogo,
              },
              // ... (Other skills)
            ],
          },
          {
            skillGroupName: "Skills & Discipline",
            skills: [
              {
                skillName: "Design Thinking",
              },
              {
                skillName: "UX Design",
              },
              {
                skillName: "UX Research",
              },
              {
                skillName: "UI Design",
              },
              {
                skillName: "UI Principles",
              },
              {
                skillName: "Visual Hireachy",
              },
              {
                skillName: "User Flows",
              },
              {
                skillName: "Lo-Fi Wireframe",
              },
              {
                skillName: "Hi-Fi Wireframe",
              },
              {
                skillName: "Usability Testing",
              },
              {
                skillName: "Typography",
              },
              {
                skillName: "Color Theory",
              },
              {
                skillName: "Interaction Design",
              },
              {
                skillName: "Brainstorming",
              },
              {
                skillName: "Heuristic Evaluation",
              },
              {
                skillName: "Problem Solving",
              },
              {
                skillName: "Criticle Thinking",
              },
              {
                skillName: "Adaptability",
              },
              {
                skillName: "Attenion to Detail",
              },
              {
                skillName: "Communication",
              },
              {
                skillName: "Competitive Analysis",
              },
              {
                skillName: "Information Architecture",
              },
              {
                skillName: "Empathy Mapping",
              },
              {
                skillName: "User Survey",
              },
              {
                skillName: "Stakeholder Interviews",
              },
              {
                skillName: "Requirement Gathering",
              },
              {
                skillName: "User Persona & Stories",
              },
              {
                skillName: "Problem Statement",
              },
              {
                skillName: "Prototyping",
              },
              {
                skillName: "Design System",
              },
              {
                skillName: "Product Design",
              },
              {
                skillName: "Grid System",
              },
              {
                skillName: "Cross-functional Team Leadership",
              },
              {
                skillName: "Design Mentorship",
              },
              {
                skillName: "Stakeholder Management",
              },
              {
                skillName: "Agile Methodology",
              },
              {
                skillName: "Scrum",
              },
              {
                skillName: "Azure DevOps",
              },
              {
                skillName: "Manage the workload",
              },
              // ... (Other skills without images)
            ],
          },
          // ... (Other groups or individual skills)
        ]}
      />,
      <MilestonesSection
        key="milestones-section"
        milestone={{
          experience: [Briefcase, 3, "Years Experience"],
          projects: [checkDone, 20, "Done Projects"],
          customers: [faceHappy, 50, "Happy Clients"],
        }}
      />,
    ],
  },

  {
    id: 3,
    type: "clickable",
    title: "My Portfolio",
    placeholder: (
      <>
        <div className="fl mar-l-28 portwrapper">
          <img
            src={portfolio}
            alt={portfolio}
            className="fr placeholder-portfolio"
          />
        </div>
        <img src={showcase} alt={showcase} className="showcase" />
      </>
    ),
    placeholderText: "Work",
    sections: [
      <PortfolioSection
        key="portfolio-section"
        projects={[
          {
            id: 1,
            title: "Corporate Credit Card",
            description: "The Corporate Credit Card Application Project aims to streamline and enhance the process of acquiring and managing corporate credit cards within our organization. This initiative responds to the growing need for a more efficient, secure, and user-friendly solution to handle financial transactions, expense tracking, and reporting for our employees.",
            
            // Image file path or URL
            image: cccPreview,
            cover: cccCover,
            // landing: landing,
            pages: innerPages,
            technologies: ["Figma", "Illustrator", "HTML5", "SCSS", "Blaze"],
            date: "2023-01-01", // Default date
            previewLink: "https://example.com/project1", // Dynamic preview link
          },
          {
            id: 2,
            title: "Knowledgebase Information System",
            description: "The Knowledgebase Information System is a strategic initiative designed to establish a comprehensive and user-friendly knowledge management platform within our organization. This system will serve as a centralized repository for storing, organizing, and accessing critical information, fostering collaboration, and enhancing the overall efficiency of knowledge-sharing processes across teams.",
            
            // Image file path or URL
            image: KBISSPreview,
            cover: kbissCover,
            // landing: landing,
            pages: kbissinnerPages,
            technologies: ["Figma", "Illustrator", "HTML5", "SCSS", "SharePoint"],
            date: "2023-01-01", // Default date
            previewLink: "https://example.com/project1", // Dynamic preview link
          },
          {
            id: 7,
            title: "Design System",
            description: "By establishing a UI style guide, we can create a more cohesive and professional look for our digital products, and streamline the design and development process. Feel free to use our system, but in order to ensure right fit, please run the designs by our before it goes into built (dev environment). ",
            
            // Image file path or URL
            image: DesignSystemPreview,
            cover: DesignSystemCover,
            // landing: landing,
            pages: DesignSysteminnerPages,
            technologies: ["Figma", "Illustrator", "WCAG"],
            date: "2023-01-01", // Default date
            previewLink: "https://example.com/project1", // Dynamic preview link
          },
          {
            id: 3,
            title: "Global Service Framework",
            description: "The Global Service Framework (GSF) is a comprehensive initiative aimed at establishing a unified and efficient system for delivering services across diverse geographical locations. This project addresses the need for a standardized approach to service delivery, ensuring consistency, scalability, and adaptability to meet the dynamic demands of a globalized market.",
            
            // Image file path or URL
            image: FNGPreview,
            cover: fngCover,
            // landing: landing,
            pages: fnginnerPages,
            technologies: ["Figma", "Illustrator", "VueJs", "SCSS"],
            date: "2023-01-01", // Default date
            previewLink: "https://example.com/project1", // Dynamic preview link
          },
          {
            id: 4,
            title: "UK Procurement",
            description: "The UK Procurement emerges as a transformative endeavor to revolutionize the procurement landscape across the United Kingdom. This innovative platform aims to serve as a dynamic hub, facilitating seamless collaboration between buyers and suppliers, fostering transparency, and ushering in a new era of efficiency in the procurement process. By harnessing cutting-edge technology, the website endeavors to redefine how procurement opportunities are identified, managed, and fulfilled, positioning the UK at the forefront of modern, accessible, and compliant procurement practices. This initiative aligns with the overarching goal of enhancing the competitiveness of businesses, promoting fairness, and ensuring regulatory adherence in the procurement ecosystem.",
            
            // Image file path or URL
            image: ProcurementPreview,
            cover: ProcurementCover,
            // landing: landing,
            pages: ProcurementinnerPages,
            technologies: ["Figma", "Illustrator", "HTML5", "SCSS", "SharePoint"],
            date: "2023-01-01", // Default date
            previewLink: "https://example.com/project1", // Dynamic preview link
          },
          {
            id: 5,
            title: "Estimate",
            description: "The Estimate Dashboard Application is poised to revolutionize our organization's estimation processes, providing a modern, collaborative, and data-driven approach to project estimation. This initiative aligns with our commitment to leveraging technology for improved project management and decision support.",
            
            // Image file path or URL
            image: EstimatePreview,
            cover: EstimateCover,
            // landing: landing,
            pages: EstimateinnerPages,
            technologies: ["Figma", "Illustrator", "HTML5", "SCSS", ".Net"],
            date: "2023-01-01", // Default date
            previewLink: "https://example.com/project1", // Dynamic preview link
          },
          {
            id: 6,
            title: "City of Aurora",
            description: "The project involves creating a GIS (Geographic Information System) Web application specifically designed for the Government of the City of Aurora, Colorado. The primary goal is to provide a platform for accessing detailed GIS information related to ongoing construction activities on the streets of Aurora.",
            
            // Image file path or URL
            image: CityAuroraPreview,
            cover: CityAuroraCover,
            // landing: landing,
            pages: CityAurorainnerPages,
            technologies: ["Figma", "Illustrator", "HTML5", "SCSS", "GIS", "WCAG"],
            date: "2023-01-01", // Default date
            previewLink: "https://example.com/project1", // Dynamic preview link
          },
          // Add more projects as needed
        ]}
      />,
    ],
  },

  // {
  //   id: 4,
  //   type: "clickable",
  //   placeholder: "Contact",
  //   title: "Get In Touch",
  //   sections: [<AboutSection />, <ResumeContainer />, <MilestonesSection />],
  // },
];

export default portfolioData;
