// Card.js
import React from 'react';

const Card = ({ placeholder, title, onClick, clickable, imageSrc, content, sections, extraClass }) => {
  const cardClasses = `d-flex justify-end text-caps card ${clickable ? 'clickable' : ''} ${extraClass || ''}`;

  return (
    <div className={cardClasses} onClick={clickable ? onClick : null}>
{placeholder}
      {/* <h1 className={`text-xxxl card-placeholder ${!content && sections ? '' : ''}`}></h1> */}

      {content && <div className="card-content">{content}</div>}

      {/* <h2 className="text-xl card-title">{title}</h2> */}
    </div>
  );
};

export default Card;
