// Portfolio.js
import React, { useState } from "react";
import Card from "./Card";
import Modal from "./Modal";
import portfolioData from "../portfolioData";
import name from "../assets/images/name.svg";
import logo from '../assets/images/logo.svg'

const Portfolio = (cvPdfUrl ) => {
  const [selectedProject, setSelectedProject] = useState(null);

  const handleCardClick = (id, sections) => {
    setSelectedProject({ id, sections });
  };

  const handleCloseModal = () => {
    setSelectedProject(null);
  };

  return (
    <div className="portfolio">
      <header className="fl w100 d-flex justify-between align-center header">
        <a href="/" className="fl"><img src={logo} alt={logo}/></a>
        <div className="fr talk-btn">
        <button className="fr btn btn-primary pad-y-20 pad-x-12 text-m 
        " onClick={() => window.open("https://www.linkedin.com/in/ajay-kumar-00383a14b/", '_blank')}>Let’s talk ☕</button>
        <span> </span>
        <span> </span>
        <span> </span>
        <span> </span>
        </div>
      </header>
      {portfolioData.map((data) => (
        <React.Fragment key={data.id}>
          {data.type === "nonClickable" && (
            <div className="d-flex gap-4 align-end highlighted-project">
              <div className="fl d-flex flex-column mar-b-96 mar-l-28">
                <img src={name} alt={name} />
                <h2 className="fl w100 text-m mar-t-8">Creative UI/UX Designer</h2>
              </div>
              <Card
                clickable={false}
                content={data.content}
                // imageSrc={nonClickableImage}
                extraClass="first-glimpse"
              />
            </div>
          )}

          {data.type === "clickable" && (
            <Card
              title={data.title}
              placeholder={data.placeholder}
              clickable={true}
              sections={data.sections}
              onClick={() => handleCardClick(data.id, data.sections)}
            />
          )}
        </React.Fragment>
      ))}

      {selectedProject && (
        <Modal
          title={
            portfolioData.find((project) => project.id === selectedProject.id)
              .title
          }
          sections={selectedProject.sections}
          onClose={handleCloseModal}
          placeholderText={
            portfolioData.find((project) => project.id === selectedProject.id)
              .placeholderText
          }
        />
      )}
    </div>
  );
};

export default Portfolio;
