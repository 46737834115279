// CardContent.js
import React, { useState, useEffect } from "react";
import {
  Briefcase,
  CloseIcon,
  Milestone,
  Star,
  LeftArrow,
  RightArrow,
} from "./components/Icons";

const AboutSection = ({ profilePhoto, description, details, button, cvPdfUrl }) => {
  return (
  <section
    key="about-section"
    className="fl w100 d-flex gap-4 pad-x-40 mar-x-40 about-section"
  >
    <div className="fl pad-t-12 profile-photo">
      {/* Add your profile photo here */}
      <img src={profilePhoto} alt="Ajay Kumar" />
    </div>
    <div className="fl d-flex flex-column gap-1 personal-information">
      <ul className="fl w100 d-flex gap-2 text-title">
        {Object.entries(details).map(([key, value]) => (
          <li key={key} className="text-s">
            <strong className="about-list">{key}:</strong> {value}
          </li>
        ))}
      </ul>
      <p className="fl w100 text-light-l mar-b-24">
        {description.includes("<span") ? (
          <span dangerouslySetInnerHTML={{ __html: description }} />
        ) : (
          description
        )}
      </p>
      {/* <p className="fl w100 text-light-l mar-b-24">{description}</p> */}
      {Object.entries(button).map(([key, value]) => (
        <button
          key={key}
          onClick={() => window.open(cvPdfUrl, '_blank')}
          className="fr btn btn-secondary pad-y-20 pad-x-12 text-m 
        "
        >
          {value}
        </button>
      ))}
    </div>
  </section>
  );
};

// Helper function to determine if "text-based-skills" class should be applied
const shouldApplyTextBasedSkillsClass = (skills) => {
  return skills && skills.some((skill) => !skill.img);
};

const SkillsTabContent = ({ skillsData }) => (
  <div className="fl w100 d-flex flex-column gap-6">
    {/* Dynamic content for the Skills tab */}
    {skillsData.map((skillGroup, index) => (
      <div
        key={index}
        className={`fl w100 d-flex flex-column gap-3 row-gap-3 ${
          shouldApplyTextBasedSkillsClass(skillGroup.skills)
            ? "text-based-skills"
            : ""
        }`}
      >
        {skillGroup.skillGroupName && (
          <h4 className="fl w100">{skillGroup.skillGroupName}</h4>
        )}
        <div className="fl w100 d-flex flex-wrap gap-4">
          {skillGroup.skills &&
            skillGroup.skills.map((skill, skillIndex) => (
              <div
                key={`${index}-${skillIndex}`}
                className="fl d-flex flex-column justify-between align-center gap-3"
              >
                {/* Conditionally render logo-container only if there's an image */}
                {skill.img && (
                  <div className="d-flex justify-center logo-container">
                    <img src={skill.img} alt={skill.skillName} />
                  </div>
                )}
                <p className="fl">{skill.skillName}</p>
              </div>
            ))}
        </div>
      </div>
    ))}
  </div>
);

const ResumeContainer = ({ resumeData, skillsData }) => {
  const [activeTab, setActiveTab] = useState(0); // Defaulting to the Skills tab

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  useEffect(() => {
    if (window.innerWidth > 800) {
      const handleCardClick = (index) => {
        setActiveTab(index);
      };

      document
        .querySelectorAll(".resume-card, .resume-list-item")
        .forEach((element) => {
          element.addEventListener("click", () => {
            const index = parseInt(element.dataset.index, 10);
            handleTabClick(index);
            handleCardClick(index);
          });
        });

      return () => {
        document
          .querySelectorAll(".resume-card, .resume-list-item is-active")
          .forEach((element) => {
            element.removeEventListener("click", () => {
              const index = parseInt(element.dataset.index, 10);
              handleTabClick(index);
              handleCardClick(index);
            });
          });
      };
    }
  }, []);

  return (
    <section
      key="resume-container"
      className="fl w100 d-flex gap-4 pad-x-32 resume-section"
    >
      {/* Resume Menu Starts */}
      <div className="fl d-flex flex-column gap-3 pad-t-40 mar-t-40 resume-list">
        {[0, 1, 2].map((index) => (
          <div
            key={index}
            className={`fl w100 resume-list-item ${
              index === activeTab ? "is-active" : ""
            }`}
            data-index={index}
            id={`resume-list-item-${index}`}
          >
            <h6 className="fl d-flex align-center gap-1 text-m resume-list-item-title">
              {index === 0 && (
                <i className="fl icon icon-md">
                  {" "}
                  <Briefcase />
                </i>
              )}
              {index === 1 && (
                <i className="fl icon icon-md">
                  {" "}
                  <Milestone />
                </i>
              )}
              {index === 2 && (
                <i className="fl icon icon-md">
                  {" "}
                  <Star />
                </i>
              )}
              <span>{index === 0 && " Experience"}
              {index === 1 && "Favorable Turns"}
              {index === 2 && "Secret of Success"}</span>
            </h6>
          </div>
        ))}
      </div>
      {/* Resume Menu Ends */}

      {/* Resume Content Starts */}
      <div className="fr d-flex align-center justify-center flex-column resume-cards-container">
        <div className="fl w100 resume-cards">
          {[0, 1, 2].map((index) => (
            <div
              key={index}
              className={`fl w100 resume-card resume-card-${index} ${
                index === activeTab
                  ? "front"
                  : index === activeTab - 1
                  ? "back"
                  : index === activeTab - 2
                  ? "back-back"
                  : ""
              }`}
              data-index={index}
            >
              {/* Card Header Title Starts */}
              <div className="resume-card-header">
                <div className="d-flex gap-1 align-center justify-center text-sm resume-card-name">
                  {index === 0 && (
                    <i className="fl icon icon-md">
                      <Briefcase />
                    </i>
                  )}
                  {index === 1 && (
                    <i className="fl icon icon-md">
                      {" "}
                      <Milestone />
                    </i>
                  )}
                  {index === 2 && (
                    <i className="fl icon icon-md">
                      {" "}
                      <Star />
                    </i>
                  )}
                  {index === 0 && " Experience"}
                  {index === 1 && "Favorable Turns"}
                  {index === 2 && " Secret of Success"}
                </div>
              </div>
              {/* Card Header Title Ends */}

              {/* Card Body Starts */}
              <div
                className={`resume-card-body ${index === 0 && "experience"} ${
                  index === 1 && "Favorable Turns"
                } ${index === 2 && "Secret of Success"}`}
              >
                <div className="fl w100 d-flex flex-column gap-6 resume-card-body-container">
                  {/* Dynamic content based on resumeData */}
                  {index === 2 ? (
                    <SkillsTabContent skillsData={skillsData} />
                  ) : (
                    resumeData &&
                    resumeData[index] && (
                      <>
                        <div className="fl w100 d-flex gap-4 align-start justify-between">
                          <span className="fl pad-t-8 exp-count">
                            {resumeData[index].exp3}
                          </span>
                          <div className="fl org-info">
                            <h4 className="text-l mar-b-16">
                              {resumeData[index].orgname3}
                            </h4>
                            <p className="text-s">
                              {resumeData[index].jobinfo3}
                            </p>
                          </div>
                        </div>
                        <div className="fl w100 d-flex gap-4 align-start justify-between">
                          <span className="fl pad-t-8 exp-count">
                            {resumeData[index].exp2}
                          </span>
                          <div className="fl org-info">
                            <h4 className="text-l mar-b-16">
                              {resumeData[index].orgname2}
                            </h4>
                            <p className="text-s">
                              {resumeData[index].jobinfo2}
                            </p>
                          </div>
                        </div>
                        <div className="fl w100 d-flex gap-4 align-start justify-between">
                          <span className="fl pad-t-8 exp-count">
                            {resumeData[index].exp}
                          </span>
                          <div className="fl org-info">
                            <h4 className="text-l mar-b-16">
                              {resumeData[index].orgname}
                            </h4>
                            <p className="text-s">
                              {resumeData[index].jobinfo}
                            </p>
                          </div>
                        </div>
                      <div className="fl w100 d-flex gap-4 align-start justify-between">
                        <span className="fl pad-t-8 exp-count">
                          {resumeData[index].exp4}
                        </span>
                        <div className="fl org-info">
                          <h4 className="text-l mar-b-16">
                            {resumeData[index].orgname4}
                          </h4>
                          <p className="text-s">
                            {resumeData[index].jobinfo4}
                          </p>
                        </div>
                      </div>
                      <div className="fl w100 d-flex gap-4 align-start justify-between">
                        <span className="fl pad-t-8 exp-count">
                          {resumeData[index].exp5}
                        </span>
                        <div className="fl org-info">
                          <h4 className="text-l mar-b-16">
                            {resumeData[index].orgname5}
                          </h4>
                          <p className="text-s">
                            {resumeData[index].jobinfo5}
                          </p>
                        </div>
                      </div>
                      </>
                    )
                  )}
                </div>
              </div>
              {/* Card Body Ends */}
            </div>
          ))}
        </div>
      </div>
      {/* Resume Content Ends */}
    </section>
  );
};

const MilestonesSection = ({ milestone }) => (
  <section
    key="milestones-section"
    className="fl w100 d-flex justify-between gap-4 pad-x-40 milestones-section"
  >
    {milestone &&
      Object.entries(milestone).map(
        ([milestoneKey, [IconComponent, value, labelText]]) => (
          <div key={milestoneKey} className="text-center justify-center">
            <h3 className="fl w100 d-flex flex-column align-center justify-center">
              <i className="icon icon-l">
                <IconComponent />
              </i>
              <span className="fl w100 text-xl miles-title">{value}+</span>
            </h3>
            <h6 className="fl w100 text-m">{labelText}</h6>
          </div>
        )
      )}
  </section>
);

const PortfolioSection = ({ projects }) => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [loadedProjectsCount, setLoadedProjectsCount] = useState(10);
  const [prevIndex, setPrevIndex] = useState(null);
  const [nextIndex, setNextIndex] = useState(null);
  const visibleProjects = projects.slice(0, loadedProjectsCount);

  useEffect(() => {
    if (selectedProject !== null && projects.length > 0) {
      const currentIndex = projects.findIndex(
        (project) => project.id === selectedProject
      );
      const calculatedPrevIndex = (currentIndex - 1 + projects.length) % projects.length;
      const calculatedNextIndex = (currentIndex + 1) % projects.length;
      
      setPrevIndex(() => calculatedPrevIndex);
      setNextIndex(() => calculatedNextIndex);
    }
  }, [selectedProject, projects]);
  
  const handleProjectClick = (projectId, direction) => {
    setSelectedProject((prevSelectedProject) => {
      if (prevSelectedProject === projectId && !direction) {
        return null; // Clicking the same project showcase, close it
      }
  
      const currentIndex = projects.findIndex((project) => project.id === projectId);
      const newIndex =
        direction === "prev"
          ? (currentIndex - 1 + projects.length) % projects.length
          : direction === "next"
          ? (currentIndex + 1) % projects.length
          : currentIndex; // Clicking a different project showcase, open it
  
      return projects[newIndex].id;
    });
  };
  
  const handleCloseClick = (e) => {
    e.stopPropagation();
    setSelectedProject(null);
  };

  const handleLoadMoreClick = (e) => {
    e.stopPropagation();
    setLoadedProjectsCount((prevCount) => prevCount + 10);
  };

  const handleWheel = (e) => {
    const container = e.target;

    // Clear the previous timeout (if any)
    if (container.timeoutId) {
      clearTimeout(container.timeoutId);
    }

    // Set a new timeout to hide the nav-buttons-wrapper after 300 milliseconds
    container.timeoutId = setTimeout(() => {
      const isEndReached =
        container.scrollLeft + container.clientWidth === container.scrollWidth;

      if (isEndReached) {
        // Hide the nav-buttons-wrapper here
        document.querySelector(".nav-buttons-wrapper").style.display = "none";
      } else {
        // Show the nav-buttons-wrapper if it was hidden
        document.querySelector(".nav-buttons-wrapper").style.display = "flex";
      }
    }, 300);
  };
  
  return (
    <section
      key="portfolio-section"
      className="fl w100 d-grid gap-4 pad-x-32 portfolio-section"
    >
      {visibleProjects.map((project) => (
        <div key={project.id} className="project-showcase">
          <div
      className={`project-content ${
        selectedProject === project.id ? "selected" : ""
      }`}
      onClick={() => handleProjectClick(project.id)}
      
    >
      <img src={project.image} alt={project.title} />
      <h3 className="fl w100 text-m mar-t-8 project-title">
        {project.title}
      </h3>
    </div>
          {selectedProject === project.id && (
            <div
              className="pad-xy-20 project-details"
              onClick={(e) => {
                e.stopPropagation();
                // Reset display on click
                document.querySelector(".nav-buttons-wrapper").style.display =
                  "flex";
              }}
              onWheel={handleWheel}
            >
              <div className="container">
                <button
                  className="fr btn btn-transparent close-button"
                  onClick={handleCloseClick}
                >
                  <i className="fr icon icon-sm">
                  <CloseIcon />
                  </i>
                </button>

                <div className="fl w100 mar-x-32 project-cover">
                  <img src={project.cover} alt={project.title} />
                </div>

                <div className="fl w100 nav-buttons-wrapper">
                  {selectedProject !== null && (
                    <div className="fl w100 d-flex justify-between nav-buttons">
                      <button
                        className="d-flex align-center gap-1 btn btn-primary nav-button"
                        onClick={() =>
                          setSelectedProject(projects[prevIndex].id)
                        }
                      >
                        <i className="fl"><LeftArrow /></i>
                        {/* <span className="fl">Prev </span> */}
                      </button>
                      <button
                        className="d-flex align-center gap-1 btn btn-primary nav-button"
                        onClick={() =>
                          setSelectedProject(projects[nextIndex].id)
                        }
                      >
                        {/* <span className="fl">Next </span> */}
                        <i className="fl"><RightArrow /></i>
                      </button>
                    </div>
                  )}
                </div>

                <div className="fl w100 project-overview">
                  <div className="fl w100 d-flex justify-between gap-4 project-overview-content">
                    <div className="fl w100 d-flex flex-column gap-2 project-heading">
                      <h3 className="fl w100 text-xl">{project.title}</h3>
                      <div className="fl d-flex flex-wrap gap-1 tags-wrapper">
                        {project.technologies.map((tech, index) => (
                          <span key={index} className="fl text-xs-b tag">
                            {tech}
                          </span>
                        ))}
                      </div>
                    </div>

                    <div className="fl d-flex flex-column gap-1 project-descp">
                      <p className="fl text-s mar-b-16">
                        {project.description}
                      </p>
                      {/* <a
                        href={project.previewLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Live Preview
                      </a> */}
                    </div>
                  </div>
                  <div className="fl w100 mar-x-32 pad-xy-12 project-images">
                    {/* <img src={project.landing} alt={project.title} /> */}
                    <img src={project.pages} alt={project.title} />
                  </div>
                </div>
                {/* You can add more details as needed */}

                {/* Thumbnails of the previous and next projects */}
                {selectedProject === project.id && (
                  <div className="fl w100 d-flex flex-column gap-2 pad-b-32">
                    <h4 className="fl w100 text-sm">Related Projects</h4>
                    {projects.length > 1 && (
                      <div className="fl w100 d-flex justify-between gap-4 related-container">
                        <div
                          className="fl project-thumbnail"
                          onClick={() => handleProjectClick(selectedProject, "prev")}
                        >
                          {(() => {
                            const prevIndex =
                              (projects.findIndex(
                                (p) => p.id === selectedProject
                              ) -
                                1 +
                                projects.length) %
                              projects.length;
                            return (
                              <React.Fragment key={projects[prevIndex].id}>
                                <p className="fl w100 d-flex flex-column gap-2 align-start pad-y-32  project-thumbnail-title">
                                  <LeftArrow />
                                  {projects[prevIndex].title}
                                </p>
                                <img
                                  src={projects[prevIndex].image}
                                  alt={projects[prevIndex].title}
                                  onClick={() =>
                                    setSelectedProject(projects[prevIndex].id)
                                  }
                                />
                              </React.Fragment>
                            );
                          })()}
                        </div>
                        <div
                          className="fl project-thumbnail"
                          onClick={() => handleProjectClick(selectedProject, "next")}
                        >
                          {(() => {
                            const nextIndex =
                              (projects.findIndex(
                                (p) => p.id === selectedProject
                              ) +
                                1) %
                              projects.length;
                            return (
                              <React.Fragment key={projects[nextIndex].id}>
                                <p className="fl w100 d-flex flex-column gap-2 align-start pad-y-32 project-thumbnail-title">
                                  <RightArrow />
                                  {projects[nextIndex].title}
                                </p>
                                <img
                                  src={projects[nextIndex].image}
                                  alt={projects[nextIndex].title}
                                  onClick={() =>
                                    setSelectedProject(projects[nextIndex].id)
                                  }
                                />
                              </React.Fragment>
                            );
                          })()}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      ))}
      {projects.length > loadedProjectsCount && (
        <div className="load-more" onClick={handleLoadMoreClick}>
          Load More
        </div>
      )}
    </section>
  );
};

export { AboutSection, ResumeContainer, MilestonesSection, PortfolioSection };
